import React, { lazy } from 'react';
import BaseLayout from 'layout/BaseLayout';
import Loadable from 'ui-component/Loadable';
import { Navigate, RouteObject } from 'react-router-dom';

const PageNotFound = Loadable(lazy(() => import('components/PageNotFound')));
const Logoff = Loadable(lazy(() => import('components/Authentication/Logoff')));
const View = Loadable(lazy(() => import('views/View')));
const MyPanel = Loadable(lazy(() => import('views/home')));
const MyOrders = Loadable(lazy(() => import('views/orders')));
const OrderDetail = Loadable(lazy(() => import('views/orders/Details')));
const HistoricoFinanceiro = Loadable(lazy(() => import('views/historico-financeiro')));
const ShoppingCart = Loadable(lazy(() => import('views/shopping-cart')));
const Checkout = Loadable(lazy(() => import('views/shopping-cart/Checkout')));
const ShoppingCartAdded = Loadable(lazy(() => import('views/shopping-cart/Added')));
const OrderFinished = Loadable(lazy(() => import('views/shopping-cart/Finished')));
const Simulador = Loadable(lazy(() => import('views/simulador')));
const ConsultaRestricao = Loadable(lazy(() => import('views/consulta-restricao')));
const PlanosConsulta = Loadable(lazy(() => import('views/consulta-restricao/planos')));
const Provider = Loadable(lazy(() => import('views/provider')));
const Solicitacoes = Loadable(lazy(() => import('views/solicitacoes')));
const ProductDetail = Loadable(lazy(() => import('views/provider/ProductDetail')));
const MyApps = Loadable(lazy(() => import('views/my-apps')));
const Treinamentos = Loadable(lazy(() => import('views/treinamentos')));
const Pagamento = Loadable(lazy(() => import('views/pagamento')));
const Drive = Loadable(lazy(() => import('views/drive')));

const AuthenticatedRoute: RouteObject[] = [
  {
    path: '/meus-aplicativos',
    element: <BaseLayout />,
    children: [{ index: true, element: <MyApps /> }],
  },
  {
    path: '/my-apps',
    element: <BaseLayout />,
    children: [{ index: true, element: <Navigate to={'/meus-aplicativos'} /> }],
  },
  {
    path: '/treinamentos',
    element: <BaseLayout />,
    children: [{ index: true, element: <Treinamentos /> }],
  },
  {
    path: '/',
    element: <BaseLayout />,
    children: [
      { index: true, element: <Navigate to={'/home'} /> },
      {
        path: 'home',
        handle: { crumb: 'Meu Painel' },
        element: <MyPanel />,
      },
      {
        path: 'meus-pedidos',
        handle: { crumb: 'Meus Pedidos' },
        children: [
          { index: true, element: <MyOrders /> },
          { path: ':date/:orderId', element: <OrderDetail /> },
          { path: '*', element: <Navigate to={'..'} /> },
        ],
      },
      { path: 'historico-financeiro', handle: { crumb: 'Histórico Financeiro' }, element: <HistoricoFinanceiro /> },
      {
        path: 'meu-carrinho',
        handle: { crumb: 'Meu Carrinho' },
        children: [
          { index: true, element: <ShoppingCart /> },
          { path: 'added', element: <ShoppingCartAdded /> },
          {
            path: 'checkout',
            handle: { crumb: 'Checkout' },
            children: [
              { index: true, element: <Checkout /> },
              { path: 'enviado', element: <OrderFinished /> },
              { path: '*', element: <Navigate to={'..'} /> },
            ],
          },
          { path: '*', element: <Navigate to={'..'} /> },
        ],
      },
      { path: 'solicitacoes', element: <Navigate to={'/credito-parcelado'} /> },
      { path: 'credito-parcelado', handle: { crumb: 'Crédito Parcelado' }, element: <Solicitacoes /> },
      { path: 'simulador', element: <Simulador /> },
      {
        path: 'consulta-cnpj-cpf',
        children: [
          { index: true, element: <ConsultaRestricao /> },
          { path: 'planos-cnpj', element: <PlanosConsulta /> },
          { path: 'planos-cpf', element: <PlanosConsulta /> },
          { path: '*', element: <Navigate to={'..'} /> },
        ],
      },
      {
        path: 'view',
        children: [
          { index: true, element: <Navigate to={'/'} /> },
          { path: ':date/:token', element: <View /> },
          { path: '*', element: <Navigate to={'/'} /> },
        ],
      },
      {
        path: 'drive',
        children: [
          { index: true, element: <Navigate to={'/'} /> },
          { path: ':provider/:token', element: <Drive /> },
          { path: '*', element: <Navigate to={'/'} /> },
        ],
      },
      {
        path: ':provider',
        children: [
          { index: true, element: <Provider /> },
          { path: ':productAlias', element: <ProductDetail /> },
        ],
      },
      { path: 'pagamento/:dateParams/:paymentIdParams', element: <Pagamento /> },
      { path: '404', element: <PageNotFound /> },
      { path: 'logoff', element: <Logoff /> },
    ],
  },
];

export default AuthenticatedRoute;
