import { useState, useEffect, useContext } from 'react';
import { IntercomContext } from 'context/IntercomContext';
import { intercomContainer } from 'store/constant';
import useApp from './useApp';
import { Logger } from 'services';
// import { isLocalhost } from 'services';
// import { getStage } from 'utils/stage';
import useAuth from './useAuth';

async function loadScript(url, callback) {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = url;
  script.onreadystatechange = callback;
  script.onload = callback;
  script.id = 'omie-intercom';
  document.body.appendChild(script);
}

// const canIntercom = !isLocalhost && getStage() === 'prod';

function useProviderIntercom() {

  const auth = useAuth();
  const [isLoading, setLoading] = useState(true);
  // const [isVisible, setVisible] = useState(false);
  const [intercomElement, setIntercomElement] = useState(null);

  const boot = () => {
    Logger.info('Forçando boot no Intercom');
    if (window.Intercom && window.intercomSettings) {
      window.Intercom('boot', window.intercomSettings);
    }
  };

  const shutdown = () => {
    Logger.info('Forçando shutdown no Intercom');
    if (window.Intercom) {
      window.Intercom('shutdown');
    }
  };

  const show = () => {
    window.Intercom('show');
  };

  useEffect(() => {

    (async () => {

      // if (canIntercom) return;

      let interval;

      if (!window.intercomSettings) {
        window.intercomSettings = {};
      }

      async function checkIntercom() {
        const intercomLaucher = document.getElementById(intercomContainer);
        if (intercomLaucher) {
          intercomLaucher.style.cssText += 'z-index:999999999';
          clearInterval(interval);
          setIntercomElement(intercomLaucher);
          setLoading(false);
        }
      }

      async function loadIntercomTest() {
        try {
          if (auth.user && auth.user.settings && auth.user.settings.intercom && auth.user.settings.intercom.app_id) {
            const callbackLoad = async () => {
              window.intercomSettings = auth.user.settings.intercom;
              interval = setInterval(checkIntercom, 100);
            };
            const w = window;
            const i = function () {
              i.c(arguments);
            };
            i.q = [];
            i.c = function (args) {
              i.q.push(args);
            };
            w.Intercom = i;
            await loadScript('https://widget.intercom.io/widget/' + auth.user.settings.intercom.app_id, callbackLoad);
          } else {
            setLoading(false);
          }
        } catch (error) {
          console.error(error);
          setLoading(false);
        }
      }

      if (!intercomElement) {
        await loadIntercomTest();
        return;
      }

      interval = setInterval(checkIntercom, 10);

      return () => clearInterval(interval);

    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // if (!isLoading && intercomElement) {
  //   if (isVisible) {
  //     intercomElement.style.cssText += 'display:block';
  //   } else {
  //     intercomElement.style.cssText += 'display:none';
  //   }
  // }

  const settings = {
    ...window.intercomSettings,
  };

  return {
    isLoading,
    intercomElement,
    settings,
    boot,
    shutdown,
    show,
  };
}

export function ProviderIntercom({ children }) {

  const intercom = useProviderIntercom();
  const app = useApp();

  // if (canIntercom) return children;

  if (!intercom.isLoading) {
    if (window.Intercom) {
      if (app.module === 'my-apps') {
        intercom.boot();
      } else {
        intercom.shutdown();
      }
    }
  }

  Logger.debug('useProviderIntercom', intercom);

  // if (window.Intercom) {
  //   if (app.module === 'my-apps') {
  //     intercom.boot();
  //   } else {
  //     intercom.shutdown();
  //   }
  // }

  return (
    <IntercomContext.Provider value={intercom}>
      {children}
    </IntercomContext.Provider>
  );
}

const useIntercom = () => useContext(IntercomContext);

export default useIntercom;